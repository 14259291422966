import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import NavbarView from "../Navbar/NavbarView";
import FooterView from "../Footer/FooterView";
import LoginSignupView from "../Login/LoginView";
import CameraUploadView from "../Upload/UploadView";
import ConfirmationView from "../Confirmation/ConfirmationView";
import ChatView from "../Chat/ChatView";

import useMainContainerLogic from "./MainLogic";
import {
  ErrorMessage,
  ParentContainer,
  InvisibleContainerLeft,
  InvisibleContainerRight,
  LoadingOverlay,
  PersistentBanner,
} from "./MainStyles";

const MainView = () => {
  const {
    user,
    photo,
    deviceInfo,
    photoId,
    confirmedByUser,
    chatId,
    errorMessage,
    errorMessageColor,
    handleLogout,
    loginCall,
    detectionCall,
    userFeedbackCall,
    sendMessageCall,
    sendAudioCall,
    getDeviceGlossary,
    handleErrorMessage,
    setConfirmedByUser,
    loading,
    setLoading,
    setPhoto,
  } = useMainContainerLogic();

  const location = useLocation();
  const navigate = useNavigate();

  const storedUser = localStorage.getItem("user");
  if (!storedUser) {
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    }
  }

  return (
    <div className="app-container">
      <PersistentBanner>
        En esta fase de prueba, solo funcionamos con dispositivos de la Línea de
        lavado de James
      </PersistentBanner>
      {loading && (
        <LoadingOverlay>
          <CircularProgress size={60} />
        </LoadingOverlay>
      )}
      <NavbarView
        user={user}
        onBack={() => navigate("/chat/not-started")}
        onClose={handleLogout}
        className="navbar"
      />
      <div
        style={{
          width: "100vw !important",
          overflowX: "hidden",
        }}
      >
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <>
              {errorMessage && (
                <ErrorMessage
                  color={errorMessageColor}
                  onClick={() => handleErrorMessage()}
                >
                  {errorMessage}
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </ErrorMessage>
              )}
              <ParentContainer className="main-content">
                <Routes location={location}>
                  <Route
                    path="/login"
                    element={
                      <>
                        <InvisibleContainerLeft />
                        <LoginSignupView
                          onLogin={loginCall}
                          setErrorMessage={handleErrorMessage}
                        />
                        <InvisibleContainerRight />
                      </>
                    }
                  />
                  <Route
                    path="/upload"
                    element={
                      <CameraUploadView
                        photo={photo}
                        setPhoto={setPhoto}
                        loading={loading}
                        setLoading={setLoading}
                      />
                    }
                  />
                  <Route
                    path="/confirm/:chat_id"
                    element={
                      <>
                        <InvisibleContainerLeft />
                        <ConfirmationView
                          photoId={photoId}
                          detection={deviceInfo}
                          onConfirm={(chat_id, userDeviceInfo) =>
                            userFeedbackCall(chat_id, userDeviceInfo, true)
                          }
                          sectionDescription="Llene el formulario a continuación con la información de su dispositivo para poder ofrecerle una mejor asistencia"
                          setConfirmedByUser={setConfirmedByUser}
                          setErrorMessage={handleErrorMessage}
                        />
                        <InvisibleContainerRight />
                      </>
                    }
                  />
                  <Route
                    path="/chat/:chat_id"
                    element={
                      <ChatView
                        deviceInfo={deviceInfo}
                        sendMessage={sendMessageCall}
                        sendAudio={sendAudioCall}
                        getDeviceGlossary={getDeviceGlossary}
                        confirmedByUser={confirmedByUser}
                        setConfirmedByUser={setConfirmedByUser}
                        setErrorMessage={handleErrorMessage}
                        photo={photo}
                        setPhoto={setPhoto}
                      />
                    }
                  />
                </Routes>
              </ParentContainer>
            </>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <FooterView className="footer" />
    </div>
  );
};

export default MainView;
