import { Box, styled } from "@mui/material";

export const ErrorMessage = styled(Box)(({ theme, color }) => ({
  padding: "15px",
  margin: "0 auto",
  borderRadius: "5px",
  fontSize: "16px",
  textAlign: "center",
  animation: "fadeIn 0.5s ease-in-out",
  position: "fixed",
  top: "10%",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 99999,
  justifyContent: "center",
  alignItems: "center",
  width: "80%",
  backgroundColor: color === "red" ? "#f8d7da" : "#f8ecd7",
  color: color === "red" ? "#721c24" : "#72471c",
  border: color === "red" ? "1px solid #f8d7da" : "1px solid #f8ecd7",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

export const ParentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100vw",
  overflow: "auto",
  transition: "all 0.5s ease-in-out",
  margin: 0,
  padding: 0,
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

export const InvisibleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  maxWidth: "50vw",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "all 0.5s ease-in-out",
  borderRadius: "3vw",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "85vw",
    display: "block",
    boxShadow: "0 0 3vw rgba(0, 0, 0, 0.1)",
    borderRadius: "3vw",
  },
}));

export const InvisibleContainerLeft = styled(InvisibleContainer)(
  ({ theme }) => ({
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  })
);

export const InvisibleContainerRight = styled(InvisibleContainer)(
  ({ theme }) => ({
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  })
);

export const PersistentBanner = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  backgroundColor: "#f8ecd7",
  color: "#72471c",
  textAlign: "center",
  padding: "10px 0",
  zIndex: 100000,
  fontSize: "16px",
  fontWeight: "bold",
}));

export const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  backdropFilter: "blur(10px)", // Adds the blur effect
  zIndex: 9999,
}));
